// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import { PopoverBody, PopoverHeader } from '@shippypro/design-system-web';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { RowDensity } from '@web/features/ship/types';

// ########################## [PAGE COMPONENTS]
import { OrderTimeline as OrderTimelineBlock } from '@web/features/ship/components/tables/blocks';
import { TextWithCustomHover } from '@web/components/common-ui/tables/columns';

// ########################## [UTILS]
import { formatDate } from '@web/utils/@date-fns/dates';

// ########################## [HOOKS]
import useGetTabColumnSize from '@web/features/ship/hooks/tabs/useGetTabColumnSize';

const columnHelper = createColumnHelper<Order>();

export const ShippingDateColumnCode = 'date';

export const useGetShippingDateColumn = (
  rowDensity: RowDensity | null,
  handleClick: (row: Order, e: React.MouseEvent) => void,
): ColumnDef<Order, number | null> => {
  const { t } = useTranslation(),
    transToShipTable = translations.ship.table,
    transToShip = transToShipTable.columns;

  const size = useGetTabColumnSize(ShippingDateColumnCode, 150);

  return useMemo(
    () =>
      columnHelper.accessor('shipment_date', {
        header: t(transToShip.shippingDate),
        id: 'date',
        minSize: 100,
        size,
        maxSize: 300,
        meta: {
          ignoreRowClick: true,
        },
        enableSorting: true,
        cell: ({ row: { original, getIsExpanded } }) =>
          shippingDateColumnRendererFn(
            getIsExpanded() ? RowDensity.S : rowDensity,
            t,
            handleClick,
            original,
          ),
      }),
    [rowDensity, handleClick, t, transToShip.shippingDate, size],
  );
};

const shippingDateColumnRendererFn = (
  rowDensity: RowDensity | null,
  t: TFunction<'translation', undefined>,
  handleClick: (row: Order, e: React.MouseEvent) => void,
  order: Order,
) => {
  const { id, shipment_date } = order;

  const transToShipTable = translations.ship.table,
    transToShip = transToShipTable.columns,
    transPopovers = transToShip.popovers,
    transOrder = translations.order;

  const target = `shipped-date-${shipment_date}-${id}`;

  return (
    <TextWithCustomHover
      label={
        shipment_date ? (
          <div
            className="text-left text-[color:--shp-color-genericui-grey]"
            onClick={e => e.stopPropagation()}
          >
            <p className="font-bold">
              {formatDate(shipment_date, 'dd MMM yyyy', true)}
            </p>
            {rowDensity !== RowDensity.S && (
              <p
                className="text-[color:--shp-color-text-table-header] font-normal"
                data-test="shipped-date-timestring"
              >
                {formatDate(shipment_date, 'hh:mm a', true)}
              </p>
            )}
          </div>
        ) : (
          t(transOrder.info.noDate)
        )
      }
      target={target}
      type="popover"
      fitParent
    >
      <PopoverHeader>{t(transPopovers.orderEvents)}</PopoverHeader>
      <PopoverBody className="flex flex-col items-start">
        <OrderTimelineBlock
          date={shipment_date}
          translation={transPopovers.orderShipped}
          handleClick={handleClick}
          row={order}
        />
      </PopoverBody>
    </TextWithCustomHover>
  );
};
