import { Cn22Item } from '@web/types/order';
import { ETDDocumentFile } from '../components/edit-views/edit-details-and-docs/components/forms/etd-docs-form';

export type OrderCN22 = {
  contentsType?: string | null;
  nonDeliveryOption?: string | null;
  items?: Cn22Item[];
};

export type OrderETD = {
  files: ETDDocumentFile[];
};
