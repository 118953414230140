// ########################## [IMPORTANT LIBRARIES]
import { FC, memo } from 'react';

// ########################## [TYPES]
import { IPageWithTabs } from '@ds-web/components/templates/with-tabs/types';

// ########################## [PAGE COMPONENTS]
import TitleHeading from './components/heading';
import { TabsNavigator as TabsWithContent } from './components/body/TabsNavigator';

import StyledContainer from './styles';

export interface ICardWithTopTabsProps extends IPageWithTabs {
  fullHeight?: boolean;
  unpadCards?: boolean;
}

/**
 * [LAYOUT CARD WITH TOP TABS] This components holds the main layout arrangement for a page
 * with a main card with a title and navigation tabs at the top.
 * It is composed by a `Heading` component and a `TabsNavigator` component, which hold the top part
 * of the card and the actual tabs pages respectively.
 *
 * @interface ICardWithTopTabs
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const CardWithTopTabs: FC<ICardWithTopTabsProps> = memo(
  ({
    headingTitle,
    tabs,
    activeTabIndex,
    toggle,
    unpadCards = true,
    fullHeight = true,
    loadInvisibleTabs = false,
  }) => {
    const selectedTab = tabs[activeTabIndex] ?? tabs[0];

    return (
      <StyledContainer
        className="flex flex-col flex-nowrap"
        $fullHeight={fullHeight}
        $unpadCards={unpadCards}
      >
        {(headingTitle || selectedTab.secondaryContent.heading) && (
          <TitleHeading
            headingTitle={headingTitle}
            heading={selectedTab.secondaryContent.heading}
          />
        )}

        <TabsWithContent
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          toggle={toggle}
          loadInvisibleTabs={loadInvisibleTabs}
        />
      </StyledContainer>
    );
  },
);
