import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CardMetrics, KpiCardSkeleton, KpiData } from '@ds-web/components';

import { IKpiCardProps } from './types';

/**
 * A simple component that wraps a card over a centered content, with the additional ability to include a heading content.
 *
 * @interface IKpiCardProps
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const KpiCard: FC<IKpiCardProps> = memo(
  ({
    kpiName,
    kpiValue,
    kpiUnit = '',
    unitPosition,
    kpiTrend,
    invertTrendColor = false,
    isLoading = false,
    titleTooltip,
    isFetching,
    isError,
    error,
    className = '!h-auto',
  }) => {
    const { i18n } = useTranslation();

    const kpiCardDataTest = kpiName.replaceAll(' ', '-') + '-card';
    const kpiTrendId = kpiName.replaceAll(' ', '-') + '-trend';

    // Data component
    const kpiValueParsed = kpiValue.toLocaleString(i18n.language) || 0;
    const KPIDataComponent = (
      <KpiData
        code={kpiCardDataTest}
        kpiUnit={kpiUnit}
        unitPosition={unitPosition}
        kpiValue={kpiValueParsed}
        kpiTrendId={kpiTrendId}
        kpiTrend={kpiTrend}
        invertTrendColor={invertTrendColor}
        isFetching={isFetching}
        isError={isError}
        error={error}
      />
    );

    if (isLoading) return <KpiCardSkeleton />;
    return (
      <CardMetrics
        code={kpiName}
        title={kpiName}
        withHeaderBorderBottom={false}
        children={KPIDataComponent}
        data-test={kpiCardDataTest}
        titleTooltip={titleTooltip}
        className={className}
        bodyClassName="!pb-2"
        headerClassName="!pt-2"
      />
    );
  },
);
