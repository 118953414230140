import { FC, memo } from 'react';
import { format } from 'date-fns';
import classnames from 'classnames';
import { Spinner } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Badge } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

import { GetGlobalSearchResult } from '@web/features/global-search/types';

import useParseOrderEntityStatus from '@web/features/global-search/hooks/useParseOrderEntityStatus';

interface ISearchResultsProps {
  isLoading: boolean;
  isError: boolean;
  globalSearch: GetGlobalSearchResult | undefined;
}

/**
 * **[COMPONENT]** The search-results component.
 * The container of every possible result of the global search
 *
 * @interface ISearchResultsProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const SearchResults: FC<ISearchResultsProps> = memo(
  ({ isLoading, isError, globalSearch }) => {
    const { t } = useTranslation(),
      transGlob = translations.globalSearch;

    const parseOrderStatus = useParseOrderEntityStatus();

    return (
      <section
        id="search-results"
        className={classnames(
          'absolute top-12 left-0 right-0 w-full',
          'rounded-md border border-[--shp-color-genericui-light]',
          'bg-white shadow-lg',
          'max-h-[30dvh] overflow-y-auto',
        )}
      >
        {isLoading ? (
          <div className="w-full flex justify-center items-center py-4">
            <Spinner size="lg" />
          </div>
        ) : isError ? (
          <span
            id="error-results"
            className="h-[60px] p-2 w-full text-purpleish font-semibold text-center flex items-center !gap-2"
          >
            <IconHelper icon="IconAlertTriangle" size={16} />
            {t(transGlob.error)}
          </span>
        ) : (globalSearch?.data.length ?? 0) > 0 ? (
          <ul>
            {globalSearch?.data.map(item => {
              const statusData = parseOrderStatus(item.order_status);

              return (
                <li
                  key={item.order_id}
                  className={classnames(
                    'h-[60px]',
                    'flex items-center justify-between',
                    '!px-5 !py-3',
                    'hover:bg-[--shp-color-bg-highlight]',
                    'cursor-pointer',
                    'transition-colors duration-200',
                  )}
                  onClick={() =>
                    window.open(
                      `${document.location.origin}/orders/${statusData.category}?transID=${encodeURIComponent(item.transaction_id)}&mktpID=${encodeURIComponent(item.marketplace_id)}&mktpPlatform=${encodeURIComponent(item.marketplace_platform)}`,
                      '_blank',
                    )
                  }
                >
                  <div className="flex flex-col">
                    <h4 className="font-semibold text-primary">
                      {item.transaction_id}
                    </h4>
                    <p>
                      {item.recipient_name} •{' '}
                      {format(
                        new Date(item.creation_date * 1000),
                        'dd MMM yyyy',
                      )}
                    </p>
                  </div>
                  <Badge className="!p-1.5 flex items-center !gap-2 rounded-full bg-light-secondary text-light-purpleish mb-0 text-[12px] font-medium">
                    <IconHelper icon={statusData.icon} size={12} />
                    {statusData.label}
                  </Badge>
                </li>
              );
            })}
          </ul>
        ) : (
          <span
            id="no-results"
            className="h-[60px] p-2 w-full text-purpleish font-semibold text-center flex items-center !gap-2"
          >
            <IconHelper icon="IconZoomCancel" size={16} />
            {t(transGlob.notFound)}
          </span>
        )}
      </section>
    );
  },
);

export default SearchResults;
