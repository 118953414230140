import { FC, memo } from 'react';
import classnames from 'classnames';
import { IMetricTrendProps } from './types';
import { IconHelper } from '@ds-web-iconhelper';
import { UncontrolledTooltip } from '@ds-web/components';

/**
 * A component hosting the trend logics and structure.
 *
 * @interface IMetricTrendProps
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const MetricTrend: FC<IMetricTrendProps> = memo(
  ({
    value = 0,
    invertTrendColor = false,
    iconUp = 'IconArrowUp',
    iconDown = 'IconArrowDown',
    id,
    tooltip,
    className,
  }) => {
    const isTrendNegative =
      (value < 0 && !invertTrendColor) || (value > 0 && invertTrendColor);
    const isTrendPositive =
      (value > 0 && !invertTrendColor) || (value < 0 && invertTrendColor);
    return (
      <div id="metric-trend" data-test="metric-trend" className={className}>
        {(value || value === 0) && (
          <>
            <div
              id={id}
              className={classnames('d-flex justify-end', {
                'text-success': isTrendPositive,
                'text-danger': isTrendNegative,
              })}
            >
              {(value > 0 || value < 0) && (
                <IconHelper
                  className="mr-1"
                  icon={value > 0 ? iconUp : iconDown}
                ></IconHelper>
              )}
              <span>{value === 0 ? '-' : value + '%'}</span>
            </div>
            {tooltip && (
              <UncontrolledTooltip target={id}>
                {value === 0 ? tooltip.stagnant : tooltip.trending}
              </UncontrolledTooltip>
            )}
          </>
        )}
      </div>
    );
  },
);
