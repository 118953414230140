import { FetchArgs } from '@reduxjs/toolkit/query/react';

import { BASE_MICROSERVICE_ALLOWED_METHODS, getMicroserviceQuery } from '..';

import { objectToQueryString } from '@web/utils/functions/objects';

type SEARCHENGINE_ALLOWED_METHODS = Exclude<
  BASE_MICROSERVICE_ALLOWED_METHODS,
  'POST' | 'PATCH' | 'PUT' | 'DELETE'
>;

/**
 * This method scaffolds the query object for the "Search Engine" microservice API request.
 * It takes a url that goes AFTER the base one, an allowed REST method code.
 *
 * @param {string} url The url of the microservice endpoint.
 * @param {SEARCHENGINE_ALLOWED_METHODS} method The REST method code.
 * @returns {FetchArgs} The query object.
 */
function getSearchEngineQuery<ArgT>(
  url: string,
  method: SEARCHENGINE_ALLOWED_METHODS,
  args?: ArgT,
): FetchArgs {
  return getMicroserviceQuery<ArgT>(
    `${process.env.NX_SEARCH_ENGINE_URL}/${url}`,
    method,
    args,
  );
}

/**
 * This method scaffolds the query object for the "Search Engine" microservice API request.
 *
 * @param {SEARCHENGINE_ALLOWED_METHODS} method The REST method code.
 * @returns {FetchArgs} The query object.
 */
export function getSearchEngineGlobalSearchQuery(
  method: SEARCHENGINE_ALLOWED_METHODS,
  args: object,
  url: string,
): FetchArgs {
  const queryString = createGlobalSearchQueryString(args);

  return getSearchEngineQuery(`${url}${queryString}`, method, args);
}

function createGlobalSearchQueryString(args: object) {
  const queryString = objectToQueryString(args, undefined);
  return `?${queryString}`;
}
