import { useState, useCallback, useMemo } from 'react';
import { useEffectOnce } from 'react-use';

import {
  forceQzTrayConnection,
  getQZioUpdatedConfig,
  isQzConnected,
  loadQZIoPrinterList,
  startQZioConnection,
} from '@web/utils/@qz-tray/functions';
import { PrinterListType } from '@web/utils/@qz-tray/types';
import { isTestingEnv } from '@web/utils/functions';

import { IQzTrayManagerContexttProps } from '../contexts/QzTrayManagerContext';

/**
 * `[HOOK]` This hook instances every default info prop related to the QzTray connection.
 * It is also used as a localised state that can get updated by an external source (I.e. A dedicated context).
 *
 * N.B. **ALREADY INSTANTIATED AT THE NEAR-TOP OF THE CODE TREE, NO NEED TO USE THIS ANYWHERE ELSE**
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 *
 * @returns {IQzTrayManagerContexttProps} All of the info needed for the QzTray connection to function
 */
const useInstanceQzTrayManager = (): IQzTrayManagerContexttProps => {
  const [qzConnected, setQzConnected] = useState<boolean>(false);
  const [isQzConnecting, setIsQzConnecting] = useState<boolean>(false);
  const [forceTestConnection, setForceTestConnection] =
    useState<boolean>(false);

  const isQzConnectionUp = useMemo(
    () => qzConnected || forceTestConnection,
    [qzConnected, forceTestConnection],
  );

  /* Printers state */
  const [printersList, setPrintersList] = useState<PrinterListType>([]);
  const [printerChanged, setPrinterChanged] = useState<boolean>(false);

  const selectedPrinter = useMemo(
    () => printersList.find(printer => printer.isDefault) ?? null,
    [printersList],
  );

  const setPrinter = useCallback(
    (printer: string, mode: 'setFavourite' | 'setDefault') => {
      // update the object isFavourite/isDefault prop
      const key = mode === 'setFavourite' ? 'isFavourite' : 'isDefault';
      const newPrinterList = printersList.map(pr => ({
        ...pr,
        [key]: pr.name === printer,
      }));

      // set default printer
      if (mode === 'setDefault') {
        const cf = getQZioUpdatedConfig();
        cf.setPrinter(printer);
      } else {
        // put favourite printer selected as first element to show on top
        const favIdx = newPrinterList.findIndex(el => el.isFavourite);
        newPrinterList.unshift(newPrinterList.splice(favIdx, 1)[0]);
      }

      // update printers
      setPrintersList(newPrinterList);
      setPrinterChanged(true);
    },
    [printersList],
  );

  const qzReconnectHandler = useCallback(
    (
      retries?: number,
      onReconnectSuccess?: () => void,
      onReconnectFail?: () => {},
    ) => {
      setIsQzConnecting(true);
      startQZioConnection(retries)
        .then(() => {
          setQzConnected(true);
          setIsQzConnecting(false);
          onReconnectSuccess && onReconnectSuccess();
        })
        .catch(() => {
          setQzConnected(false);
          setIsQzConnecting(false);
          onReconnectFail && onReconnectFail();
        });
    },
    [],
  );

  const checkQzConnection = useCallback(() => {
    if ((isTestingEnv() && isQzConnectionUp) || isQzConnected()) {
      setQzConnected(true);
      setIsQzConnecting(false);
      return;
    }

    setQzConnected(false);
    setIsQzConnecting(false);
  }, [isQzConnectionUp]);

  /* Force cypress connection - set a check connection interval every 2s */
  useEffectOnce(() => {
    forceQzTrayConnection(() => {
      setForceTestConnection(true);
      loadQZIoPrinterList(true, printersList, setPrintersList);
    });

    !isTestingEnv() &&
      startQZioConnection()
        .then(() => {
          loadQZIoPrinterList(true, printersList, setPrintersList);
        })
        .catch(e => {
          setPrintersList([]);
          console.error(e);
        });

    checkQzConnection();
    const checkQzInterval = setInterval(() => {
      checkQzConnection();
    }, 2000);
    return () => {
      clearInterval(checkQzInterval);
    };
  });

  return useMemo(
    () => ({
      isQzConnectionUp,
      isQzConnecting,
      printersList,
      selectedPrinter,
      printerChanged,

      setPrinter,
      setPrintersList,
      qzReconnectHandler,
    }),
    [
      isQzConnectionUp,
      isQzConnecting,
      printersList,
      selectedPrinter,
      printerChanged,

      setPrinter,
      setPrintersList,
      qzReconnectHandler,
    ],
  );
};

export default useInstanceQzTrayManager;
