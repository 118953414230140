import { FC, memo } from 'react';
import { isNumber } from 'lodash';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import { Spinner } from 'reactstrap';

import { IAnalyticsCardProps } from './types';

import { IconHelper } from '@ds-web-iconhelper';

import {
  CardMetrics,
  ChartMetricsSkeleton,
  MetricTrend,
} from '@ds-web/components';

/**
 * A component that wraps a card with title header a centered content with analytics data
 * and children (a table, a graph, etc.)
 *
 * @interface IAnalyticsCardProps
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
export const AnalyticsCard: FC<IAnalyticsCardProps> = memo(
  ({
    summaryData,
    name,
    isLoading = false,
    titleTooltip,
    isFetching = false,
    isError = false,
    error,
    dataTest,
    className,
    children,
    rightSideOptWidget,
  }) => {
    const { i18n, t } = useTranslation();
    const transCommon = translations.common;

    const kpiCardDataTest = name.replaceAll(' ', '-') + '-card';
    const code = name.replaceAll(' ', '-') + '-code';

    if (isLoading)
      return <ChartMetricsSkeleton className="max-h-[30rem] mb-2" />;

    return (
      <CardMetrics
        className={classnames(className, '!h-auto')}
        code={code}
        title={name}
        dynamicBodyHeight
        titleTooltip={titleTooltip}
        data-test={dataTest ?? code}
        rightSideOptWidget={rightSideOptWidget}
      >
        {isFetching ? (
          <div className="flex w-full h-full items-center justify-center">
            <Spinner size="xl" />
            <p className="ml-2">{t(transCommon.loading)}</p>
          </div>
        ) : error && isError ? (
          <span className="flex items-center justify-center">
            <IconHelper
              icon="IconAlertCircleFilled"
              size={18}
              className="text-red-500 mr-2"
            />
            <p>{error}</p>
          </span>
        ) : (
          <section
            id="chart-container"
            className="w-full flex flex-col justify-between items-start"
          >
            {summaryData && (
              <div
                id="chart-summary-block"
                className="w-full flex flex-row justify-start items-baseline !gap-6"
              >
                {summaryData?.map(
                  ({ value, unit, trend, subtitle, invertTrendColor }) => {
                    const valueParsed =
                      value.toLocaleString(i18n.language) || 0;
                    return (
                      <div className="flex items-center">
                        <span
                          id="chart-summary"
                          className="font-bold text-3xl text-black flex flex-col"
                        >
                          {unit ?? ''}
                          {valueParsed || 0}
                          {subtitle && (
                            <p className="subtitle text-gray-500 text-sm font-light truncate">
                              {subtitle}
                            </p>
                          )}
                        </span>
                        {isNumber(trend) && (
                          <MetricTrend
                            id={kpiCardDataTest}
                            value={trend}
                            invertTrendColor={invertTrendColor}
                            iconUp="IconArrowUpRight"
                            iconDown="IconArrowDownLeft"
                            className="pb-1 ml-2"
                          />
                        )}
                      </div>
                    );
                  },
                )}
              </div>
            )}
            <div
              id="analytics-card-container"
              className={classnames(
                {
                  '!mt-5': summaryData,
                },
                'recharts-wrapper',
              )}
            >
              {children}
            </div>
          </section>
        )}
      </CardMetrics>
    );
  },
);
