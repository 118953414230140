import { FC, memo, useMemo } from 'react';

import { MetricTrend, UncontrolledTooltip } from '@ds-web/components';

import { IKpiDataProps, unitPositionEnum } from './types';

import { Spinner } from 'reactstrap';
import { IconHelper } from '@ds-web-iconhelper';

/**
 * A simple component that manage different state of the KPI data card.
 *
 * @interface IKpiCardProps
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
export const KpiData: FC<IKpiDataProps> = memo(
  ({
    code,
    kpiUnit,
    unitPosition = unitPositionEnum.RIGHT,
    kpiValue,
    kpiTrendId,
    kpiTrend = 0,
    invertTrendColor = false,
    isFetching = false,
    isError = false,
    error = '',
  }) => {
    const tooltipTarget = `${code.replaceAll(' ', '-')}-kpi-data-error-icon`;

    const metricSummaryBody = useMemo(() => {
      if (isFetching) return <Spinner size="sm" />;
      if (isError)
        return (
          <span id={tooltipTarget}>
            <IconHelper
              icon="IconAlertCircleFilled"
              size={18}
              className="text-red-500"
            />
            <UncontrolledTooltip target={tooltipTarget}>
              {error}
            </UncontrolledTooltip>
          </span>
        );

      return (
        <>
          <span
            id="metrics-summary"
            className="font-bold text-3xl text-black"
            data-test="kpi-value"
          >
            {unitPosition === 'left' ? (
              <>
                {kpiUnit} {kpiValue}
              </>
            ) : (
              <>
                {kpiValue} {kpiUnit}
              </>
            )}
          </span>
          <MetricTrend
            id={kpiTrendId}
            value={kpiTrend}
            invertTrendColor={invertTrendColor}
            iconUp="IconArrowUpRight"
            iconDown="IconArrowDownLeft"
          />
        </>
      );
    }, [
      isFetching,
      isError,
      error,
      kpiUnit,
      kpiValue,
      kpiTrend,
      kpiTrendId,
      invertTrendColor,
      tooltipTarget,
    ]);

    return (
      <div
        id="metrics-summary-block"
        className="w-full flex justify-between items-baseline !gap-4"
      >
        {metricSummaryBody}
      </div>
    );
  },
);
