import i18n from 'i18next';
import { translations } from '@shippypro/translations';

import {
  TwoRowsOneVideoLayoutPaywallInfo,
  TwoRowsOneImageLayoutPaywallInfo,
} from '@web/features/paywall/types';
import { Features } from '@web/features/_global/types';

import {
  ctaProfessional,
  ctaSales,
} from '@web/features/paywall/utils/common/CTAs';

type KPIPaywallInfoModel = {
  [key in Features]:
    | TwoRowsOneVideoLayoutPaywallInfo
    | TwoRowsOneImageLayoutPaywallInfo;
};

const utmGetProfessionalSourceER = 'easy-return';
const utmGetProfessionalCampaignPerf = 'get-professional';

const utmSalesSourceER = 'easy-return';
const utmSalesSourceAP = 'api-integration';
const utmSalesSourceSC = 'ship-and-collect';
const utmSalesCampaignPerf = 'contact-sales';

const transEasyReturnPaywall = translations.easyReturn.paywalls.easyReturn,
  transApiPagePaywall = translations.apiPages.paywalls.apiPage,
  transShipAndCollectPaywall =
    translations.shipAndCollect.paywalls.shipAndCollect;

/**
 * This Object Parser is a strongly-typed info collector for features' paywalls.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
const FeaturePaywallInfo: KPIPaywallInfoModel = {
  [Features.EasyReturn]: {
    feature: i18n.t(transEasyReturnPaywall.title),
    description: transEasyReturnPaywall.featureDesc,
    details: Object.keys(transEasyReturnPaywall.featureDetails).map(key =>
      i18n.t(transEasyReturnPaywall.featureDetails[key]),
    ),
    cta: ctaProfessional(
      utmGetProfessionalSourceER,
      utmGetProfessionalCampaignPerf,
    ),
    secondaryCta: ctaSales(utmSalesSourceER, utmSalesCampaignPerf),
    imageUrl: i18n.t(transEasyReturnPaywall.imageUrl),
    gradientColor: 'var(--shp-color-genericui-purple-gradient)',
  },

  [Features.Api]: {
    feature: i18n.t(transApiPagePaywall.title),
    description: transApiPagePaywall.featureDesc,
    details: Object.keys(transApiPagePaywall.featureDetails).map(key =>
      i18n.t(transApiPagePaywall.featureDetails[key]),
    ),
    cta: ctaSales(utmSalesSourceAP, utmSalesCampaignPerf),
    imageUrl: i18n.t(transApiPagePaywall.imageUrl),
    gradientColor: 'var(--shp-color-genericui-green-gradient)',
  },
  [Features.ShipAndCollect]: {
    feature: i18n.t(transShipAndCollectPaywall.title),
    description: transShipAndCollectPaywall.featureDesc,
    details: Object.keys(transShipAndCollectPaywall.featureDetails).map(key =>
      i18n.t(transShipAndCollectPaywall.featureDetails[key]),
    ),
    cta: ctaSales(utmSalesSourceSC, utmSalesCampaignPerf),
    imageUrl: i18n.t(transShipAndCollectPaywall.imageUrl),
    gradientColor: 'var(--shp-color-genericui-green-gradient)',
  },
};

// ---------------------------- [FINAL EXPORT]
export default FeaturePaywallInfo;
