import { Fragment } from 'react';
import { Row } from '@tanstack/react-table';

import { IReactTableProps } from '../../types';

import TableRow from './Row';
import SubComponentContainer from './SubComponentContainer';

/**
 * This component is the UI representation of a `react-table` body.
 * It displays the `data` list into an actual table rows layout and manages:
 * * Row Pagination (w/ page OR row if not paginated);
 * * Row Selection (w/ checkbox);
 * * Row Expansion (w/ icon);
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {FC} The `React Table` body, fully rendered & managed.
 */
function Body<T extends object>(props: IReactTableProps<T>) {
  const { getRowModel, customComponents } = props;

  // Choosing between a virtualised set of rows (if passed) or a plain one
  const rows =
    customComponents?.virtualisation?.virtualRows ?? getRowModel().rows;

  return (
    <tbody className="react-table-body rdt_TableBody">
      {/* Adding empty padded rows to the top of the table body if the virtualisation is active */}
      {(customComponents?.virtualisation?.paddingTop ?? 0) > 0 && (
        <tr>
          <td
            style={{
              height: `${customComponents?.virtualisation?.paddingTop}px`,
            }}
          />
        </tr>
      )}
      {rows.map(muddyRow => {
        // Getting the actual row from the virtualised model (if passed) or the normal one
        const row = (
          !!customComponents?.virtualisation?.virtualRows
            ? getRowModel().rows[muddyRow.index]
            : muddyRow
        ) as Row<T>;

        return (
          <Fragment key={row.id}>
            {/* ROW     | This is the actual component that displays every cell into a table row layout */}
            <TableRow
              row={row}
              tableProps={props}
              rowStyle={
                customComponents?.virtualisation?.virtualRows
                  ? {
                      height: `${muddyRow.size}px`,
                      transform: `translateY(${
                        muddyRow.start - muddyRow.index * muddyRow.size
                      }px)`,
                    }
                  : {}
              }
            />
            {/* SUB-CMP | This custom component is displayed (passing the full row) when its row (^) is expanded */}
            <SubComponentContainer row={row} tableProps={props} />
          </Fragment>
        );
      })}
      {/* Adding empty padded rows to the bottom of the table body if the virtualisation is active */}
      {(customComponents?.virtualisation?.paddingBottom ?? 0) > 0 && (
        <tr>
          <td
            style={{
              height: `${customComponents?.virtualisation?.paddingBottom}px`,
            }}
          />
        </tr>
      )}
    </tbody>
  );
}

export default Body;
