// ########################## [IMPORTANT LIBRARIES]
import { FC, memo } from 'react';

// ########################## [TYPES]
import { ISecondaryContentProps } from '@ds-web/components/templates/with-tabs/types';

interface ITitleHeadingProps extends Omit<ISecondaryContentProps, 'preBody'> {
  headingTitle?: string;
}

/**
 * [LAYOUT CARD WITH TOP TABS - TITLE HEADING] This component is used to render the title heading of the main layout card.
 * It sports a title on the top-left and an additional custom content on the top-right (hidden by a spinner when loading said content).
 *
 * @interface ITitleHeadingProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const TitleHeading: FC<ITitleHeadingProps> = memo(
  ({ headingTitle, heading }) => {
    return (
      <div className="flex flex-[0_0_auto] flex-col w-full !px-4 justify-start">
        <div className="w-full flex items-center">
          <h2
            className="card-with-top-tabs-title font-medium text-[24px] text-purpleish my-1 sm:my-0 flex-[1_0_auto]"
            data-test="TitleHeading-withtabs-header-title"
          >
            {headingTitle}
          </h2>
          {heading?.sideContentRender}
        </div>
      </div>
    );
  },
);

export default TitleHeading;
