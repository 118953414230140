import { FC, memo, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Button } from '@shippypro/design-system-web';
import { Color, StyleType } from '@shippypro/design-system-web/enums';

import PaywallContext from '@web/features/paywall/contexts/PaywallContext';

/**
 * This block displayes the CTA buttons (dismiss, buy) that the user can click
 * after viewing the paywall modal.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const CtaSection: FC = memo(() => {
  const { t } = useTranslation(),
    trans = translations.paywall.ctas;

  const { info, closePaywall } = useContext(PaywallContext)!;

  return (
    <div id="paywall-cta">
      <hr className="!mt-0 !mb-[11px]" />
      <div className="flex justify-between items-center !gap-2 px-2 md:!px-8">
        {info && info.secondaryCta ? (
          <Button
            styleType={StyleType.flat}
            color={Color.secondary}
            data-test="paywall-dismiss"
            className="text-primary"
            onClick={
              /* istanbul ignore next */
              () => info?.secondaryCta?.onClick()
            }
          >
            {info?.secondaryCta?.text}
          </Button>
        ) : (
          <Button
            styleType={StyleType.flat}
            color={Color.secondary}
            data-test="paywall-dismiss"
            className="text-primary"
            onClick={closePaywall}
          >
            {t(trans.notNow)}
          </Button>
        )}
        {info && (
          <Button
            styleType={StyleType.filled}
            color={Color.primary}
            data-test="paywall-buy"
            className="text-primary"
            onClick={
              /* istanbul ignore next */
              () => info?.cta.onClick()
            }
          >
            {/* istanbul ignore next */}
            {info?.cta.text}
          </Button>
        )}
      </div>
    </div>
  );
});

export default CtaSection;
