// ########################## [IMPORTANT LIBRARIES]
import { FC, memo } from 'react';

// ########################## [DESIGN SYSTEM]
import { ModalHeader, ModalBody } from '@shippypro/design-system-web';

// ########################## [CONTEXTS]
import { IPaywallContextProps } from '@web/features/paywall/contexts/PaywallContext';

// ########################## [PAGE COMPONENTS]
import StyledPaywallModal from '@web/features/paywall/pages/two-rows-single-image/styles';
import TopInfo from '@web/features/paywall/components/top-info';
import FeatureDetails from '@web/features/paywall/components/feature-details';
import CtaSection from '@web/features/paywall/components/cta-section';
import { TwoRowsOneImageLayoutPaywallInfo } from '@web/features/paywall/types';

interface ITwoRowsSingleImagePaywallProps
  extends Omit<
    IPaywallContextProps<TwoRowsOneImageLayoutPaywallInfo>,
    'design'
  > {}

/**
 * **DESIGN: Two Rows - One Image**
 *
 * This Design of the Paywall displays two separated rows. One has the name, desc and details of the feature (+ bottom cta)
 * and the other on the right has a `Feature Preview` image displayed on it.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const TwoRowsSingleImagePaywall: FC<ITwoRowsSingleImagePaywallProps> =
  memo(({ isOpen, info, closePaywall }) => {
    return (
      <StyledPaywallModal
        isOpen={isOpen}
        toggle={closePaywall}
        $gradientColor={info?.gradientColor}
        data-test="paywall-modal"
      >
        <ModalHeader toggle={closePaywall} />
        <ModalBody className="flex flex-col-reverse lg:flex-row justify-around items-start">
          <section
            id="paywall-info"
            className="w-full lg:w-[40%] 2xl:min-h-[700px] rounded-br-[6px] lg:rounded-br-none"
          >
            <div className="overflow-y-auto">
              <TopInfo />
              <FeatureDetails />
            </div>
            <CtaSection />
          </section>
          <section
            id="paywall-img"
            className="w-full !hidden lg:!flex lg:w-[60%]"
          >
            {info && (
              <img
                src={`https://cdn.shippypro.com/${info.imageUrl}`}
                alt={info.feature}
                className="w-[85%] lg:w-[90%] xl:w-[85%] 2xl:w-[75%] m-5 lg:!m-0"
              />
            )}
          </section>
        </ModalBody>
      </StyledPaywallModal>
    );
  });
