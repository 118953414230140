import { Address } from '@web/types/common/address';
import { RateCarrierOptions } from '@web/types/global';
import { ErrorRate, Order, Parcel, Rate } from '@web/types/order';
import { RateCarrierOptionsValuesObject } from '@web/types/order/carrier-options';
import { OrderValidation } from './validation';
import { OrderDetails } from './details';
import { OrderCN22, OrderETD } from './documents';

export type OrderToManage = {
  original: Order;
  addresses: {
    recipient: Address;
    sender: Address;
  };
  details: OrderDetails;
  cn22Data: {
    isLoading: boolean;
    cn22: OrderCN22;
  };
  etdData: {
    isLoading: boolean;
    etdDocs: OrderETD;
  };
  parcelsData: { isLoading: boolean; parcels: Parcel[] };
  ratesData: {
    isLoading: boolean;
    rates: OrderCarrierRates;
  };
  validationData: {
    isLoading: boolean;
    validations: OrderValidation;
  };
};

export type GoodsDetails = OrderDetails & OrderCN22;

export type OrderCarrierRates = {
  available: CarrierRate<Rate>[];
  error: CarrierRate<ErrorRate>[];
};

export type CarrierRate<TRate extends Rate | ErrorRate> = {
  isSelected: boolean;
  isError: boolean;
  rate: TRate;
  carrierOptions: RateCarrierOptions[];
  selectedCarrierOptionsObject: RateCarrierOptionsValuesObject | undefined;
  isHidden: boolean;
};
