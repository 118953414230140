import { FC, memo, useRef, useState } from 'react';
import classnames from 'classnames';
import { useClickAway } from 'react-use';

import SearchInput from './search-input';
import SearchResults from './search-results';

import { useGetGlobalSearch } from '@web/features/global-search/hooks/api/useGetGlobalSearch';

interface ISearchBarProps {}

/**
 * **[COMPONENT]** The search-bar component.
 * The Reactive Global Search text bar
 *
 * @interface ISearchBarProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const SearchBar: FC<ISearchBarProps> = memo(() => {
  const { onFetch, globalSearch, isError, isLoading, isFetching } =
    useGetGlobalSearch();

  const [query, setQuery] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickAway(ref, () => setIsOpen(false));

  return (
    <div
      ref={ref}
      id="search-bar"
      className={classnames(
        'group relative',
        'min-w-[50%] max-w-[500px] w-full',
        '!p-0.5 !pl-4',
        'bg-white rounded-full',
        'border border-[--shp-color-genericui-light] focus-within:!border-primary',
        'transition-colors duration-200',
        'flex justify-between items-center',
      )}
    >
      <SearchInput
        query={query}
        setQuery={setQuery}
        setIsOpen={setIsOpen}
        onFetch={onFetch}
      />
      {isOpen &&
        query.length > 0 &&
        (isLoading || isFetching || !!globalSearch || isError) && (
          <SearchResults
            isLoading={isLoading || isFetching}
            isError={isError}
            globalSearch={globalSearch}
          />
        )}
    </div>
  );
});

export default SearchBar;
