import { Dispatch, FC, memo, SetStateAction, useRef } from 'react';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Button, Input } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

interface ISearchInputProps {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;

  setIsOpen: Dispatch<SetStateAction<boolean>>;

  onFetch: (query: string) => void;
}

/**
 * **[COMPONENT]** The search-input component.
 * The input that manages the text value for the Global Search.
 *
 * @interface ISearchInputProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const SearchInput: FC<ISearchInputProps> = memo(
  ({ query, setQuery, setIsOpen, onFetch }) => {
    const { t } = useTranslation(),
      trans = translations,
      transSearch = trans.globalSearch,
      transCommon = trans.common.form;

    const queryInvalid = query.length < 3 || query.length > 256;
    const queryValid = query.length > 2 && query.length < 255;

    const inputRef = useRef<HTMLInputElement>(null);

    return (
      <>
        <div className="w-full flex items-center !gap-2">
          <IconHelper
            icon="IconSearch"
            size={20}
            className="shrink-0 text-primary"
          />
          <Input
            id="search-input"
            innerRef={inputRef}
            className="w-[98%] !border-none !shadow-none !rounded-none !p-0"
            placeholder={t(transSearch.search)}
            value={query}
            onFocus={() => setIsOpen(true)}
            onChange={e => setQuery(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter' && queryValid) {
                onFetch(query);
                setIsOpen(true);
              }
            }}
          />
        </div>
        <div
          className={classnames(
            'shrink-0 flex items-center !gap-2',
            'transition-opacity duration-150',
            {
              'opacity-0 !cursor-text': queryInvalid,
              'opacity-100': queryValid,
            },
          )}
          onClick={() => queryInvalid && inputRef.current?.focus()}
        >
          <IconHelper
            icon="IconX"
            size={18}
            className={classnames('shrink-0 text-purpleish !mr-2', {
              'cursor-text': queryInvalid,
              'cursor-pointer': queryValid,
            })}
            onClick={() => setQuery('')}
          />
          <Button
            id="search-btn"
            className={classnames(
              'shrink-0 !py-2 !px-4',
              '!rounded-full !bg-primary !text-white focus:!bg-primary focus:!text-white',
            )}
            onClick={() => {
              onFetch(query);
              setIsOpen(true);
            }}
            disabled={!query.length}
          >
            {t(transCommon.search)}
          </Button>
        </div>
      </>
    );
  },
);

export default SearchInput;
