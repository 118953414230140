import { useCallback, useMemo, useRef } from 'react';

import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';
import { Properties } from 'csstype';

type CSSPropertiesType = Properties<string | number, string & {}>;

/**
 * This hook does all of the heavy lifting for creating a set of objects and utilities
 * used to virtualise a list of rows. It spews out the ref for the main container,
 * the virtualised rows and the styles needed for the UI list and rows to work.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @param {T} rows The types rows of your list
 * @param {((index: number) => number)} estimateSize A function to estimate how big the UI rows will be (get as close as possible to avoid glitches)
 * @param {number} overscan The amount of UI rows that will be pre-rendered (top and bottom) to avoid whitespace while rendering the list
 * @returns A bunch of stuff
 */
export const usePrepareListVirtualisation = <T extends unknown>(
  rows: T[],
  estimateSize: (index: number) => number,
  overscan: number = 5,
) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => containerRef.current,
    estimateSize,
    overscan,
  });

  const { getTotalSize } = rowVirtualizer;
  const virtualBlockStyles: CSSPropertiesType = useMemo(
    () => ({
      // The addition of the `rows.length` is completely futile, but it's there to avoid the useEffect call to complain
      // from adding it to its dependencies, since it's the only way (library-wise) to update the true length of the list
      // when adding or removing items (yeah I know).
      height: `${getTotalSize() ?? rows.length}px`,
      width: '100%',
      position: 'relative',
    }),
    [getTotalSize, rows.length],
  );

  const getRowStyles = useCallback(
    (virtualRow: VirtualItem): CSSPropertiesType => ({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: `${virtualRow.size}px`,
      transform: `translateY(${virtualRow.start}px)`,
    }),
    [],
  );

  return useMemo(
    () => ({
      ...rowVirtualizer,
      containerRef,
      virtualBlockStyles,
      getRowStyles,
    }),
    [rowVirtualizer, virtualBlockStyles, getRowStyles],
  );
};
