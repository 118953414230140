import styled, { css } from 'styled-components';

const MediaQueries = css`
  @media (max-width: 768px) {
    /* Hide scrollbar */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      /* Chrome, Safari and Opera */
      display: none;
    }
  }
`;

/**
 * The custom styling of the ShippyPro SideMenu component.
 *
 * @link You can find the actual specs here: https://zpl.io/7woXDQp
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const StyledSideMenu = styled.div`
  .sp-side-menu {
    ${MediaQueries}

    width: 60px;

    color: white;

    svg,
    span,
    ::after {
      color: white;
    }

    .ext-link {
      opacity: 0;
    }

    .category-label {
      color: var(--shp-color-text-section-title-inverse) !important;
    }

    .menu-link-selected {
      background-color: var(--shp-color-bg-dark-highlight) !important;

      span {
        font-weight: bold;
      }
    }

    .menu-link-accent {
      background-color: var(--shp-color-genericui-accent) !important;
    }

    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.102);
    .accordion {
      .accordion-header {
        border-radius: 0.357rem;
        &:hover {
          .ext-link {
            opacity: 1 !important;
          }
        }
        &.accordion-header-btn-pl-0 {
          .accordion-button {
            padding-left: 0 !important;
          }
        }
        .accordion-button {
          transition:
            background-color 0s,
            color 0s;
          transition: 0.25s ease-in-out 0s;
          &:hover {
            transform: translateX(2px);
          }
          &::after {
            transform: rotate(180deg);
          }
        }
        .accordion-button:not(.collapsed)::after {
          transform: rotate(0);
        }
        &.bg-primary .accordion-button::after {
          filter: brightness(0) invert(1);
        }
      }
      .accordion-body {
        padding: 1rem 0;
        .menu-link {
          &:hover {
            .ext-link {
              opacity: 1 !important;
            }
            span {
              transition: transform 0.25s ease-in-out 0s;
              transform: translateX(2px);
            }
          }
          &.bg-primary .accordion-button::after {
            filter: brightness(0) invert(1);
          }
          &.ml-9 {
            margin-left: 2.3rem;
          }
        }
      }
      .accordion-item {
        color: white;
        background-color: var(--shp-color-genericui-primary);

        border-bottom: none;
        .accordion-button {
          background-color: transparent !important;

          padding: 0.5rem 1rem !important;
        }

        &.locked-accordion {
          .accordion-button::after,
          .accordion-collapse {
            display: none !important;
          }
        }

        h2,
        .menu-link {
          &:hover {
            background-color: var(--shp-color-bg-dark-hover);
          }
        }
      }
    }
    &.collapsed {
      .accordion {
        .accordion-item {
          .accordion-button {
            padding: 0 !important;
            svg {
              margin-left: 15px;
            }
          }
        }
      }
    }
    .user-menu {
      margin-left: -7px;
      margin-right: -7px;
      margin-bottom: -7px;
      border-top: 1px solid var(--shp-color-bg-dark-highlight);

      .accordion {
        .accordion-header {
          .accordion-button {
            padding-top: 15px !important;
            padding-bottom: 15px !important;

            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            &::after {
              width: 0 !important;
            }
          }
        }
      }
    }
  }

  .sp-side-menu-overlay {
    background-color: rgba(1, 22, 39, 0.7);
  }
`;
