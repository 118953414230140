import { useEffect } from 'react';

import { Pagination } from '@ds-web/components/atoms/ui/pagination/Pagination';

import { PaginationArrows } from '@ds-web/tokens/enums';

interface ITablePagination {
  rowCount: number;
  currentPage: number;
  rowsPerPage: number;
  setPage: (page: number) => void;
  onChangePage: (page: number) => void;

  selected: any[];

  paginationText: string;
  selectionText: string;

  transFunc: (text: string, config: any) => string;
}

const TablePagination = ({
  transFunc,
  paginationText,
  selectionText,
  setPage,
  ...rest
}: ITablePagination) => {
  useEffect(() => {
    setPage(rest.currentPage);
  }, [setPage, rest.currentPage]);

  return (
    <Pagination
      arrows={PaginationArrows.separated}
      getPaginationString={(currentPage, _pages, rowCount) =>
        transFunc
          ? rest.selected && rest.selected.length !== 0
            ? transFunc(selectionText, {
                selected: rest.selected.length,
                total: rowCount,
              })
            : transFunc(paginationText, {
                start: rest.rowsPerPage * (currentPage - 1) + 1,
                end: Math.min(rest.rowsPerPage * currentPage, rowCount),
                total: rowCount,
              })
          : rest.selected && rest.selected.length !== 0
          ? `Selected ${rest.selected.length} of ${rowCount} teammates`
          : `Showing ${rest.rowsPerPage * (currentPage - 1) + 1} to ${Math.min(
              rest.rowsPerPage * currentPage,
              rowCount,
            )} of ${rowCount} entries`
      }
      {...rest}
    />
  );
};

export default TablePagination;
