import { FC, memo, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

// ########################## [REDUX SLICE]
import { useSelector } from 'react-redux';
import { selectChangelogModal } from './selectors';

// ########################## [DESIGN SYSTEM]
import { ModalBody } from '@shippypro/design-system-web';
import { FullSize } from '@shippypro/design-system-web/enums';

// ########################## [COMMON COMPONENTS]
import CardLoading from '@web/components/common-ui/wrappers/card/CardLoading';

// ########################## [PAGE COMPONENTS]
import { StyledChangelogModal as Modal } from './components/Modal';
import Header from './components/Header';
import Footer from './components/Footer';
import Empty from './components/Empty';
import Post from './components/Post';

// ########################## [HOOKS]
import { useGetChangelog } from '@web/features/_global/hooks/api/useGetChangelog';

/**
 * **FEATURE: Changelog**
 *
 * This feature is responsable for opening up a handy modal with a list of every 'New Release' post created
 * by the ShippyPro teams.
 * Said feature uses an API Endpoint (`PortalGetChangelog`) to fetch the latest posts and last publication date.
 * The modal also offers an 'Infinite Scroll' feature, to allow the user to check older releases when scrolling down.
 *
 * @link https://app.tettra.co/teams/shippypro/pages/changelog-modal
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Changelog: FC = memo(() => {
  const { changelog, loadMore, publishedPosts, currPage, isLoading } =
    useGetChangelog();
  const { open } = useSelector(selectChangelogModal);

  return (
    <Modal className="modal-wrapper" size={FullSize.lg} isOpen={open}>
      <Header />
      <ModalBody
        id="changelog-container"
        className="overflow-y-scroll max-h-[70vh]"
      >
        <InfiniteScroll
          scrollableTarget="changelog-container"
          dataLength={publishedPosts.length}
          next={loadMore}
          hasMore={
            publishedPosts.length > 0 && currPage < (changelog?.lastPage ?? 1)
          }
          loader={
            <div className="py-2">
              <CardLoading />
            </div>
          }
        >
          {!publishedPosts.length && !isLoading ? (
            <Empty />
          ) : (
            publishedPosts
              // Sorting the posts by the boolean 'fixed_on_top' property
              .sort((a, b) =>
                a.fixed_on_top === b.fixed_on_top ? 0 : a.fixed_on_top ? -1 : 1,
              )
              .map((post, i) => (
                <Fragment key={`${post.project_id}_${post.id}`}>
                  <Post post={post} />
                  {i < publishedPosts.length - 1 && <hr className="!mb-5" />}
                </Fragment>
              ))
          )}
        </InfiniteScroll>
      </ModalBody>
      <Footer />
    </Modal>
  );
});
