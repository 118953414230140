import { useCallback, useMemo } from 'react';

import { useIntercom } from 'react-use-intercom';

import { isProductionEnv } from '@web/utils/functions';

type TrackingCallback = (code: string) => void;
type HJWindow = Window & typeof globalThis & { hj: Function };

export type EventType = 'analytics' | 'survey' | 'product-tour';

/**
 * [HOOK] This hook implements an unimportant and easily changeable set of libraries that can track determinate events
 * in the platform, and returns a handler that wraps said functions to use wherever in the code.
 *
 * For now, the libraries are listed below, but they can be easily changed ONLY here in the future.
 * * Analytics:
 *   * [Hotjar](https://www.hotjar.com/)
 * * Product Tour:
 *   * [Intercom](https://www.intercom.com/)
 *
 * @returns {TrackingCallback} The callback function to call to submit your tracking events.
 */
const useSubmitTrackingEvent = (eventsTypes: EventType[]): TrackingCallback => {
  // Setting up the intercom event tracker
  const { trackEvent } = useIntercom();

  // Stopping possible infinite loops if the passed array is not memoized
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedEvents = useMemo(() => eventsTypes, []);

  return useCallback(
    code => {
      memoizedEvents.forEach(receiver => {
        switch (receiver) {
          case 'analytics':
          case 'survey':
            isProductionEnv() && (window as HJWindow).hj('event', code);
            break;
          case 'product-tour':
            isProductionEnv() && trackEvent(code);
            break;
          default:
            throw new Error('Receiver not configured');
        }
      });
    },
    [memoizedEvents, trackEvent],
  );
};

export default useSubmitTrackingEvent;
