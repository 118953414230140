import { IconHelper } from '@ds-web-iconhelper';
import { i18n, translations } from '@shippypro/translations_restricted';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export enum AlertSeverity {
  warning = 'warning',
  danger = 'danger',
}

export type IAlertProps = {
  id?: string;

  title: string | JSX.Element;
  description: string | JSX.Element;

  cancelBtn?: string;
  onCancel?: () => void;
  cancelBtnClass?: string;

  confirmBtn: string;
  onConfirm?: () => void;
  confirmBtnClass?: string;

  severity?: AlertSeverity;
};

/**
 * Displays a common SweetAlert with a simple text and description.
 * Optionally displays a checkbox to hide the alert in the future (works on localStorage)
 *
 * @interface IAlertProps
 * @returns {Promise<void>}
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const showSweetAlert = async ({
  id,
  title,
  description,
  cancelBtn,
  onCancel,
  cancelBtnClass,
  confirmBtn,
  onConfirm,
  confirmBtnClass,
  severity,
}: IAlertProps): Promise<void> => {
  const t = await i18n.then(t => t);

  const localStorageKey = `sa-hide-${id}`;

  const hideAlert = Boolean(localStorage.getItem(localStorageKey));
  if (hideAlert) {
    onConfirm && onConfirm();
    return;
  }

  const btnColor =
    severity === AlertSeverity.danger ? 'btn-danger' : 'btn-warning';

  const titleIcon =
    severity === AlertSeverity.danger
      ? 'IconAlertCircleFilled'
      : 'IconAlertTriangleFilled';

  const titleIconColor =
    severity === AlertSeverity.danger ? 'text-danger' : 'text-warning';

  return withReactContent(
    Swal.mixin({
      customClass: {
        title: '!justify-self-start] !text-[21px]',
        htmlContainer: '!justify-self-start !text-[14px] !text-left',
        input: '!justify-start !text-[14px]',
        actions: '!mr-5',
        confirmButton: `btn !text-[14px] ${btnColor} ${confirmBtnClass}`,
        cancelButton: `btn btn-flat-primary !text-[14px] !mr-5 ${cancelBtnClass}`,
      },
      buttonsStyling: false,
    }),
  )
    .fire({
      title: (
        <span className="flex items-center gap-1">
          <IconHelper icon={titleIcon} size={18} className={titleIconColor} />{' '}
          {title}
        </span>
      ),
      html: description,

      input: id ? 'checkbox' : undefined,
      inputValue: hideAlert ? 1 : 0,
      inputPlaceholder: t(translations.common.dontShowAgain),
      inputValidator: result => {
        if (result) localStorage.setItem(localStorageKey, 'true');
        return null;
      },

      cancelButtonText: cancelBtn,
      showCancelButton: !!cancelBtn,
      confirmButtonText: confirmBtn,
      reverseButtons: true,
      inputAttributes: { class: '!ml-0' },
    })
    .then(result => {
      if (result.isConfirmed) {
        onConfirm && onConfirm();
      } else {
        onCancel && onCancel();
      }
    });
};
