import { FC, memo, useRef } from 'react';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import {
  Button,
  TooltipWrapperByID,
  TooltipWrapperByREF,
} from '@ds-web/components';
import { IconHelper } from '@ds-web-iconhelper';
import { Color, StyleType } from '@ds-web/tokens/enums';
import { useGetDeviceSize } from '@ds-web/utils/hooks';

import { IFiltersSummaryBtnProps } from './types';

/**
 * The button shown in the top-right corner of the page layout, below the tabs.
 * It shows how many filters are currently applied and has a quick way to "get rid of'em".
 * Normally it opens the FiltersForm drawer when clicked.
 *
 * @interface IFiltersSummaryBtnProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const FiltersSummaryBtn: FC<IFiltersSummaryBtnProps> = memo(
  ({
    tooltipContent,
    isTooltipActive,
    disabled,
    submittedFiltersCount,
    onClear,
    toggleDrawer,
    isFilterFormClearable,
  }) => {
    const { t } = useTranslation(),
      trans = translations.common;

    // Changing the amount and size of the pills if the display is smaller than 768px
    const { isTabletHoriz } = useGetDeviceSize();

    // Anchor a ref to the main button that opens the filters form drawer
    const btnRef = useRef<HTMLButtonElement>(null);

    return (
      <Button
        innerRef={btnRef}
        onClick={
          toggleDrawer
            ? () => {
                // Open the drawer with all its data
                toggleDrawer();
                // Blur the button to avoid the focus on it when the drawer is closed
                btnRef.current?.blur();
              }
            : undefined
        }
        disabled={disabled}
        color={Color.primary}
        styleType={StyleType.flat}
        className={classnames(
          'h-full flex items-center !gap-2 !rounded-full !px-3 !py-3',
          {
            'text-muted': disabled,
            '!cursor-default': toggleDrawer === undefined,
          },
        )}
        data-test="filters-summary-button"
      >
        <TooltipWrapperByID
          target="filters-draweropen-button"
          text={tooltipContent}
          active={isTooltipActive}
        >
          <div className="flex items-center justify-between !gap-2">
            <IconHelper icon="IconFilter" size={18} />
            <span>
              {isTabletHoriz
                ? submittedFiltersCount
                : t(trans.countFilters, { count: submittedFiltersCount })}
            </span>
          </div>
        </TooltipWrapperByID>
        {(isFilterFormClearable ?? submittedFiltersCount > 0) && (
          <TooltipWrapperByREF
            fullWidth={false}
            content={t(trans.clearFilters)}
            containerProps={{
              className: classnames('clear-all-filters-btn', {
                'cursor-pointer': toggleDrawer === undefined,
              }),
              onClick: e => {
                e.stopPropagation();
                e.preventDefault();

                onClear();
              },
            }}
          >
            <IconHelper icon="IconX" size={18} />
          </TooltipWrapperByREF>
        )}
      </Button>
    );
  },
);
