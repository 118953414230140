// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { RowDensity } from '@web/features/ship/types';

// ########################## [UTILS]
import { getOrderOrderType } from '@web/hooks/orders/useGetOrderOrderType';
import { getCompositeKeyFromOrder } from '@web/utils/functions/orders';

// ########################## [PAGE COMPONENTS]
import { LinkWithBadge } from '@web/components/common-ui/tables/columns';
import OrderTypeBadge from '@web/components/common-features/order/OrderTypeBadge';
import { NotesBadge } from '@web/components/common-ui/tables/columns/badges/NotesBadge';
import { IsReturnBadge } from '@web/components/common-ui/tables/columns/badges/IsReturnBadge';
import { DocumentsBadge } from '@web/components/common-ui/tables/columns/badges/DocumentsBadge';
import { MarketplaceBadge } from '@web/components/common-ui/tables/columns/badges/MarketplaceBadge';
import { CarrierBadge } from '@web/components/common-ui/tables/columns/badges/CarrierBadge';

// ########################## [HOOKS]
import useGetTabColumnSize from '@web/features/ship/hooks/tabs/useGetTabColumnSize';

const columnHelper = createColumnHelper<Order>();

export const ShippedExternalIdColumnCode = 'order';

/**
 * Column holding info about the external id (aka transaction id) of a shipped order
 *
 * @returns react-table column configuration for the external id column
 */
export const useGetShippedExternalIdColumn = (
  rowDensity: RowDensity | null,
  spaceCodeAndIcon: boolean = true,
): ColumnDef<Order, string> => {
  const { t } = useTranslation(),
    transCommon = translations.common;

  const size = useGetTabColumnSize(ShippedExternalIdColumnCode, 248);

  return useMemo(
    () =>
      columnHelper.accessor('external_id', {
        header: t(transCommon.order),
        minSize: 150,
        size,
        maxSize: 450,
        meta: {
          ignoreRowClick: true,
        },
        enableSorting: true,
        cell: ({ row: { original, getIsExpanded } }) =>
          externalIdRenderFn(
            t,
            getIsExpanded() ? RowDensity.S : rowDensity,
            spaceCodeAndIcon,
            original,
          ),
      }),
    [rowDensity, spaceCodeAndIcon, t, transCommon.order, size],
  );
};

const externalIdRenderFn = (
  t: TFunction<'translation', undefined>,
  rowDensity: RowDensity | null,
  spaceCodeAndIcon: boolean,
  order: Order,
) => {
  const {
    id,
    external_id,
    items_count,
    notes,
    documentation,
    marketplace_id,
    marketplace_platform,
    marketplace_updated,
    order_status,
    carrier_id,
    carrier_name,
  } = order;

  const transShipTable = translations.ship.table,
    transShip = transShipTable.columns,
    transPopovers = transShip.popovers;
  // TODO: this is gonna be useful when two tooltips will be used, for every id not truncated
  // transTooltips = translations.order.tooltips;

  const text = external_id || id.toString();
  // TODO: this is gonna be useful when two tooltips will be used, for every id not truncated
  // const textTooltip = t(transTooltips.openOrderPage);

  const shouldShowDocumentIcon = documentation && documentation.length > 0;
  const shouldShowReturnIcon =
    marketplace_platform === 'Returns' || order.is_normal_return;
  const shouldShowNotesIcon = !!notes;

  const orderType = getOrderOrderType(order);

  return (
    <div
      className="w-full flex flex-col space-y-1"
      onClick={e => e.stopPropagation()}
    >
      <div className="flex items-center space-x-3 justify-start max-w-[80%]">
        <LinkWithBadge
          url={`/orders/shipped${getCompositeKeyFromOrder(order)}`}
          className="break-all"
          text={text}
          textTooltip={text}
          textTarget={`shipped-order-items-${id}`}
          badge={items_count && items_count > 1 ? items_count : undefined}
          badgeTooltip={t(transPopovers.orderItemsExp, {
            num: items_count,
          })}
          badgeTarget={`shipped-order-items-badge-${id}`}
          invertTextColor={!external_id}
          allowEvents
        />
        {(shouldShowReturnIcon ||
          shouldShowNotesIcon ||
          shouldShowDocumentIcon ||
          orderType) && (
          <span className="flex space-x-3">
            {shouldShowNotesIcon && (
              <NotesBadge
                orderId={id}
                orderStatus={order_status}
                notes={notes}
              />
            )}
            {shouldShowDocumentIcon ? (
              <DocumentsBadge documentation={documentation} orderId={id} />
            ) : null}
            {rowDensity === RowDensity.S && orderType && (
              <OrderTypeBadge orderType={orderType} compact={true} />
            )}
            {rowDensity === RowDensity.S && shouldShowReturnIcon && (
              <IsReturnBadge compact={true} />
            )}
          </span>
        )}
      </div>
      {rowDensity !== RowDensity.S && (
        <div className="flex items-center justify-start !gap-2 pl-2">
          <div className="flex items-center !gap-0.5">
            <CarrierBadge carrierId={carrier_id} carrierName={carrier_name} />
            <MarketplaceBadge
              mrkId={marketplace_id}
              mrkPlatform={marketplace_platform}
              mrkUpdated={marketplace_updated}
            />
            {id}
          </div>
          {orderType && <OrderTypeBadge orderType={orderType} />}
          {shouldShowReturnIcon && <IsReturnBadge />}
        </div>
      )}
    </div>
  );
};
