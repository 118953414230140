import { Pagination as UIPagination } from '@ds-web/components/atoms/ui/pagination/Pagination';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import { IReactTableProps } from '../../types';

import { TooltipWrapperByID } from '@ds-web/components/molecules/ui/tooltips/tooltip-wrapper-byid/TooltipWrapperByID';
import { PaginationArrows } from '@ds-web/tokens/enums';
import { InteractableIconButton } from '@ds-web/components/molecules/ui/buttons/interactable-icon-button/InteractableIconButton';

import { waitForElem } from '@ds-web/utils/functions';

/**
 * This component is the UI representation of a `react-table` pagination block.
 * It shows a pagination text + the analogue state handling buttons.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {FC} The `React Table` bottom pagination block, fully rendered & managed.
 */
function Pagination<T extends object>(props: IReactTableProps<T>) {
  const { t } = useTranslation(),
    backTrans = translations.common.backToTop;

  const {
    customComponents,
    getPageCount,
    getState,
    setPageIndex,
    getSelectedRowModel,
    getRowModel,
  } = props;

  const {
    pagination: { pageIndex, pageSize },
  } = getState();

  const targetID = 'scroll-to-table-top';

  return (
    <div className="react-table-pagination w-full !mx-2 flex !gap-2">
      <UIPagination
        arrows={PaginationArrows.separated}
        rowCount={
          customComponents?.remote?.totalRows ?? getRowModel().rows.length
        }
        pageCount={getPageCount()}
        rowsPerPage={pageSize}
        onChangePage={(p: number) => setPageIndex(p - 1)}
        currentPage={pageIndex + 1}
        getPaginationString={(currentPage, _pages, rowCount) =>
          // Showing a Translated bottom-left text depicting the pagination state
          // ONLY if the `translations` object is passed
          customComponents && customComponents.translations
            ? // Changing the translation string IF some rows are selected
              getSelectedRowModel().flatRows.length > 0 &&
              customComponents.translations.selectionText &&
              !customComponents.bulkActions
              ? customComponents.translations.transFunction(
                  customComponents.translations.selectionText,
                  {
                    selected: getSelectedRowModel().flatRows.length,
                    total: rowCount,
                  },
                )
              : // Showing the pagination string IF passed
                customComponents.translations.paginationText
                ? customComponents.translations.transFunction(
                    customComponents.translations.paginationText,
                    {
                      start: pageSize * (currentPage - 1) + 1,
                      end: Math.min(pageSize * currentPage, rowCount),
                      total: rowCount,
                    },
                  )
                : ''
            : ''
        }
      />
      {customComponents?.hasBackToTop && (
        <TooltipWrapperByID
          target={targetID}
          text={t(backTrans)}
          tooltipProps={{ placement: 'left' }}
        >
          <InteractableIconButton
            id={targetID}
            onClick={() =>
              waitForElem('.react-table').then(elem =>
                (elem as HTMLElement).scrollTo({
                  top: 0,
                  behavior: 'smooth',
                }),
              )
            }
            icon="IconArrowBarToUp"
          />
        </TooltipWrapperByID>
      )}
    </div>
  );
}

export default Pagination;
