import React, { FC } from 'react';

import {
  Area,
  AreaChart as DefaultAreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { IAreaChartDataProps } from './types';
import { CurveType } from 'recharts/types/shape/Curve';

import { toLocalizedDecimal } from '@ds-web/utils/functions';

/**
 * A component that render and area chart.
 *
 * @interface IAreaChartDataProps
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
export const AreaChart: FC<IAreaChartDataProps<string, string>> = ({
  data,
  tickCount = 2,
  stopColor = '#7349DD',
  XDataKey = 'xName',
  YDataKey = 'yValue',
  width = 35,
  chartPadding = { top: 25 },
  areaProp = {
    type: 'linear',
    stroke: '#7349DD',
    fillOpacity: 1,
    fill: 'url(#colorShp)',
  },
}) => {
  return (
    <ResponsiveContainer width="98%" height="99%">
      <DefaultAreaChart data={data} margin={{ right: 0, left: 0 }}>
        <defs>
          <linearGradient id="colorShp" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={stopColor} stopOpacity={0.4} />
            <stop offset="95%" stopColor={stopColor} stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid />
        <XAxis dataKey={XDataKey} tickCount={tickCount} />
        <YAxis
          dataKey={YDataKey}
          width={width}
          tickFormatter={value => toLocalizedDecimal(value, 0, 0)}
          padding={chartPadding}
        />
        <Tooltip
          content={data =>
            data.active &&
            data.payload && (
              <div className="recharts-custom-tooltip">
                <p className="font-light">{data.label}</p>
                <div className="active">
                  {data.payload.map(i => {
                    return (
                      <div className="flex items-center" key={i.dataKey}>
                        <span className="font-bold text-xl text-black">
                          {i.payload[i.dataKey ?? 0]}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          }
        />
        <Area
          type={areaProp.type as CurveType}
          dataKey={YDataKey}
          stroke={areaProp.stroke}
          fillOpacity={areaProp.fillOpacity}
          fill={areaProp.fill}
        />
      </DefaultAreaChart>
    </ResponsiveContainer>
  );
};
