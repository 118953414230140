import React from 'react';
import { lowerFirst } from 'lodash';

import { i18n, translations } from '@shippypro/translations';

import {
  ManageOrderModalView,
  OrderToManage,
} from '@web/features/manage-order-modal/types/collection';
import {
  AlertSeverity,
  IAlertProps,
  showSweetAlert,
} from '@shippypro/design-system-web/functions';

const transAlert = translations.alerts;

/**
 * This SweetAlert is used to confirm the deletion of a resource.
 * It's already configured to swap the two 'confirm' and 'cancel' buttons
 * and give them their proper styles and functions. Same goes for
 * the title, subtitle and icon of the dialog.
 *
 * @param {function} callback The function to execute on success.
 * @param {string | React.ReactElement} deleteConfirmationContent The content that should be displayed by the alert
 * @returns {Promise<void>}
 */
export const deleteResourceConfirmation = async (
  callback: () => void,
  title: string | React.ReactElement = '',
  description: string | React.ReactElement = '',
  id?: string,
): Promise<void> => {
  const t = await i18n.then(t => t),
    trans = translations.common;

  const options: IAlertProps = {
    id,

    title: title,
    description: description,

    confirmBtn: t(trans.yesDelete),
    onConfirm: callback,
    cancelBtn: t(trans.cancel),
    confirmBtnClass: 'confirm-delete',
    cancelBtnClass: 'cancel-delete',

    severity: AlertSeverity.danger,
  };

  return showSweetAlert(options);
};

/**
 * This SweetAlert is used to confirm the closing of a section with an open form.
 * It alerts the user that by continuing they'll lose any changes made up to that point.
 *
 * @param {function} callback The function to execute on success.
 * @returns {Promise<void>}
 */
export const loseChangesOnCloseConfirmation = async (
  callback: () => void,
): Promise<void> => {
  const t = await i18n.then(t => t),
    trans = translations.common,
    transForm = trans.form;

  const options: IAlertProps = {
    title: t(trans.areYouSure),
    description: t(transForm.closingSection),

    confirmBtn: t(transForm.yesClose),
    onConfirm: callback,
    cancelBtn: t(transForm.noContinue),
    confirmBtnClass: 'confirm-close-section',
    cancelBtnClass: 'cancel-close-section',

    severity: AlertSeverity.warning,
  };

  return showSweetAlert(options);
};

/**
 * This SweetAlert is used to confirm the archiving of one or more order.
 * It's already configured to swap the two 'confirm' and 'cancel' buttons
 * and give them their proper styles and functions. Same goes for
 * the title, subtitle and icon of the dialog.
 *
 * @param {number[] | string[]} ids An array of order external ids
 * @param {OrderCategory} tab The tab from which the archive action is triggered
 * @param {function} callback The function to execute on success.
 * @returns {Promise<void>}
 */
export const archiveResourceConfirmation = async (
  callback: () => void,
  ids: number[] | string[],
): Promise<void> => {
  const t = await i18n.then(t => t),
    trans = transAlert.archiveOrder;

  let description = `${t(trans.description)}${ids[0]}${t(trans.description_2)}`;
  if (ids.length > 1)
    description = t(trans.description, {
      count: ids.length,
    });

  const options: IAlertProps = {
    id: 'archive-orders-alert',

    title: t(trans.title),
    description,

    confirmBtn: t(trans.confirmBtn, { count: ids.length }),
    onConfirm: callback,
    cancelBtn: t(trans.cancelBtn),
    confirmBtnClass: 'confirm-archive',
    cancelBtnClass: 'cancel-archive',

    severity: AlertSeverity.warning,
  };

  return showSweetAlert(options);
};

/**
 * This SweetAlert is used to confirm the unarchiving of one ore more order.
 * It's already configured to swap the two 'confirm' and 'cancel' buttons
 * and give them their proper styles and functions. Same goes for
 * the title, subtitle and icon of the dialog.
 *
 * @param {number[] | string[]} ids An array of order external ids
 * @param {function} callback The function to execute on success.
 * @returns {Promise<void>}
 */
export const unarchiveResourceConfirmation = async (
  callback: () => void,
  ids: number[] | string[],
): Promise<void> => {
  const t = await i18n.then(t => t),
    trans = transAlert.unarchiveOrder;

  let description = `${t(trans.description)}${ids[0]}${t(trans.description_2)}`;
  if (ids.length > 1)
    description = t(trans.description, {
      count: ids.length,
    });

  const options: IAlertProps = {
    id: 'unarchive-orders-alert',
    title: t(trans.title),
    description,

    confirmBtn: t(trans.confirmBtn, { count: ids.length }),
    onConfirm: callback,
    cancelBtn: t(trans.cancelBtn),
    confirmBtnClass: 'confirm-unarchive',
    cancelBtnClass: 'cancel-unarchive',

    severity: AlertSeverity.warning,
  };

  return showSweetAlert(options);
};

/**
 * This SweetAlert is used to confirm the deletion of the ShippyPro notes of an order.
 *
 * @param {function} callback The function to execute on success.
 * @returns {Promise<void>}
 */
export const deleteNoteConfirmation = async (
  callback: () => void,
): Promise<void> => {
  const t = await i18n.then(t => t),
    trans = transAlert.deleteNote;

  const options: IAlertProps = {
    title: t(trans.title),
    description: t(trans.description),

    confirmBtn: t(trans.confirmBtn),
    onConfirm: callback,
    cancelBtn: t(trans.cancelBtn),
    confirmBtnClass: 'confirm-delete-note-action',
    cancelBtnClass: 'cancel-delete-note-action',

    severity: AlertSeverity.danger,
  };

  return showSweetAlert(options);
};

export const confirmZplViewReadOnly = async (
  callback: () => void,
): Promise<void> => {
  const t = await i18n.then(t => t),
    transCommon = translations.common,
    qzDisconnected = translations.ship.buttons.print.qz.readOnlyMode;

  const options: IAlertProps = {
    title: t(qzDisconnected.title),
    description: '',

    confirmBtn: t(qzDisconnected.downloadLabel),
    onConfirm: callback,
    cancelBtn: t(transCommon.cancel),
    confirmBtnClass: '',
    cancelBtnClass: '',

    severity: AlertSeverity.danger,
  };

  return showSweetAlert(options);
};

/**
 * This SweetAlert is used to confirm the closing of a note.
 * It alerts the user that they can either 'keep editing' the note or discard the changes
 *
 * @param {function} onKeepEditing The function to execute when keep editing is clicked
 * @param {function} onDiscardChanges The function to execute when discard changes is clicked
 * @returns {Promise<void>}
 */
export const keepEditingOrDiscardConfirmation = async (
  onKeepEditing: () => void,
  onDiscardChanges?: () => void,
): Promise<void> => {
  const t = await i18n.then(t => t),
    trans = transAlert.discardNote;

  const options: IAlertProps = {
    title: t(trans.title),
    description: t(trans.description),

    confirmBtn: t(trans.confirmBtn),
    onConfirm: () => onDiscardChanges,
    cancelBtn: t(trans.cancelBtn),
    onCancel: onKeepEditing,
    confirmBtnClass: 'confirm-close-section',
    cancelBtnClass: 'cancel-close-section',

    severity: AlertSeverity.warning,
  };

  return showSweetAlert(options);
};

/**
 * This SweetAlert is used to confirm the closing of the filters' drawer without being applied.
 *
 * @param {function} callback The function to execute on success.
 * @returns {Promise<void>}
 */
export const closeFiltersConfirmation = async (
  callback: () => void,
): Promise<void> => {
  const t = await i18n.then(t => t),
    trans = transAlert.filtersDrawerClose;

  const options: IAlertProps = {
    id: 'close-filters-drawer',

    title: t(trans.title),
    description: t(trans.description),

    confirmBtn: t(trans.confirmBtn),
    onConfirm: callback,
    cancelBtn: t(trans.cancelBtn),

    severity: AlertSeverity.warning,
  };

  return showSweetAlert(options);
};

/**
 * This SweetAlert is used to tell the user that the "Manage Order" Modal cannot
 * be closed while processing orders.
 *
 * @param {string} title The title of the alert
 * @param {string} text The text of the alert
 * @returns {Promise<void>}
 */
export const closeModalWhileSubmittingBlock = async (
  title: string,
  text: string,
  confirm?: string,
): Promise<void> => {
  const t = await i18n.then(t => t),
    transCommon = translations.common;

  const options: IAlertProps = {
    title,
    description: text,

    confirmBtn: confirm ?? t(transCommon.okGotIt),
    onConfirm: () => undefined,

    severity: AlertSeverity.warning,
  };

  return showSweetAlert(options);
};

/**
 * This SweetAlert is used to ask the user whether he wants to ship again the
 * just edited shipped orders or not.
 *
 * @param {string} title The title of the alert
 * @param {string} text The text of the alert
 * @param {function} callback The action if modal is confirmed
 * @returns {Promise<void>}
 */
export const confirmShipAgainBlock = async (
  title: string,
  text: string,
  callback: (orders?: OrderToManage[]) => void,
  orders: OrderToManage[] = [],
): Promise<void> => {
  const t = await i18n.then(t => t),
    transCommon = translations.common,
    transModal =
      translations.manageOrderModal.views.editOrdersShipped.confirmModal;

  const options: IAlertProps = {
    title,
    description: text,

    confirmBtn: t(transModal.confirm),
    onConfirm: () => callback(orders),
    cancelBtn: t(transCommon.cancel),
    confirmBtnClass: 'confirm-close-section',
    cancelBtnClass: 'cancel-close-section',

    severity: AlertSeverity.warning,
  };

  return showSweetAlert(options);
};

/**
 * This SweetAlert is used to tell the user the the "Manage Order" Modal that closing
 * it will have consequences.
 *
 * @param {string} title The title of the alert
 * @param {string} text The text of the alert
 * @returns {Promise<void>}
 */
export const confirmModalClosure = async (
  title: string,
  text: string,
  callback: () => void,
  confirm: string,
  cancel?: string,
  id?: string,
): Promise<void> => {
  const t = await i18n.then(t => t),
    transCommon = translations.common;

  const options: IAlertProps = {
    id,

    title,
    description: text,

    confirmBtn: confirm,
    onConfirm: callback,
    cancelBtn: cancel ?? t(transCommon.cancel),
    confirmBtnClass: 'confirm-close-modal',
    cancelBtnClass: 'cancel-close-modal',

    severity: AlertSeverity.danger,
  };

  return showSweetAlert(options);
};

/**
 * This SweetAlert is used to confirm the closing of the manage order modal dialog.
 * It gets dynamic translations based on the ManageOrderModalView provided
 *
 * @param {ManageOrderModalView} view The manage order modal view we are currently in
 * @param {function} onConfirm The function to execute when discard changes is clicked
 * @param {function} onCancel The function to execute when keep editing is clicked
 * @returns {Promise<void>}
 */
export const confirmCloseModalByView = async (
  id: string,
  view: keyof typeof ManageOrderModalView,
  onConfirm?: () => void,
  onCancel?: () => void,
  hasSuggestion?: boolean,
): Promise<void> => {
  const t = await i18n.then(t => t),
    transClose = translations.manageOrderModal.dialogs.close,
    trans = transClose[lowerFirst(view)] ?? transClose.shipOrders;

  const options: IAlertProps = {
    id,

    title: t(trans.title),
    description: hasSuggestion ? t(trans.textCheckAddress) : t(trans.text),

    confirmBtn: t(trans.confirm),
    onConfirm: onConfirm,
    cancelBtn: t(trans.cancel),
    onCancel: onCancel,
    confirmBtnClass: 'confirm-close-modal',
    cancelBtnClass: 'cancel-close-modal',

    severity: AlertSeverity.danger,
  };

  return showSweetAlert(options);
};

/**
 * This SweetAlert is used to confirm the merge of two orders.

 * @param {function} callback The function to execute on success.
 * @param {number[] | string[]} ids An array of order external ids
 * @param {string} text The text of the alert
 * @returns {Promise<void>}
 */
export const mergeOrdersConfirmation = async (
  callback: () => void,
  ids: number[] | string[],
  text: string,
): Promise<void> => {
  const t = await i18n.then(t => t),
    trans = transAlert.mergeOrders;

  const options: IAlertProps = {
    title: t(trans.title, { count: ids.length }),
    description: text,

    confirmBtn: t(trans.confirmBtn),
    onConfirm: callback,
    cancelBtn: t(trans.cancelBtn),
    confirmBtnClass: 'confirm-merge-action',
    cancelBtnClass: '',

    severity: AlertSeverity.warning,
  };

  return showSweetAlert(options);
};

/**
 * This SweetAlert is used to confirm the deletion of carrier invoices.
 *
 * @param {function} callback The function to execute on success.
 * @returns {Promise<void>}
 */
export const deleteInvoiceConfirmation = async (
  callback: () => void,
  length: number,
): Promise<void> => {
  const t = await i18n.then(t => t),
    transCommon = translations.common,
    trans = translations.carrierInvoiceAnalysis.dialogs.delete;

  const options: IAlertProps = {
    title: t(trans.title, { count: length }),
    description: t(trans.description, { count: length }),

    confirmBtn: t(trans.confirm, { count: length }),
    onConfirm: callback,
    cancelBtn: t(transCommon.cancel),
    confirmBtnClass: 'confirm-delete-invoice-action',
    cancelBtnClass: '',

    severity: AlertSeverity.danger,
  };

  return showSweetAlert(options);
};

/**
 * This SweetAlert is used to confirm the deletion of carrier invoices notes.
 *
 * @param {function} callback The function to execute on success.
 * @returns {Promise<void>}
 */
export const deleteInvoiceNoteConfirmation = async (
  callback: () => void,
): Promise<void> => {
  const t = await i18n.then(t => t),
    transCommon = translations.common,
    trans = translations.carrierInvoiceAnalysis.dialogs.deleteNote;

  const options: IAlertProps = {
    title: t(trans.title),
    description: t(trans.description),

    confirmBtn: t(trans.confirm),
    onConfirm: callback,
    cancelBtn: t(transCommon.cancel),
    confirmBtnClass: 'confirm-delete-invoice-note-action',
    cancelBtnClass: '',

    severity: AlertSeverity.danger,
  };

  return showSweetAlert(options);
};

/**
 * This SweetAlert is used to confirm the deletion of carrier invoices shipments notes.
 *
 * @param {function} callback The function to execute on success.
 * @returns {Promise<void>}
 */
export const deleteInvoiceShipmentNoteConfirmation = async (
  callback: () => void,
): Promise<void> => {
  const t = await i18n.then(t => t),
    transCommon = translations.common,
    trans = translations.carrierInvoiceAnalysis.dialogs.deleteNoteToShipment;

  const options: IAlertProps = {
    title: t(trans.title),
    description: t(trans.description),

    confirmBtn: t(trans.confirm),
    onConfirm: callback,
    cancelBtn: t(transCommon.cancel),
    confirmBtnClass: 'confirm-delete-invoice-shipment-note-action',
    cancelBtnClass: '',

    severity: AlertSeverity.danger,
  };

  return showSweetAlert(options);
};
