import { FC, memo } from 'react';

import { IconHelper } from '@ds-web-iconhelper';

import { ICondensedMetricsBodyProps } from './types';

/**
 * This Component renders a condensed view of a metric, with an icon,
 * a subtitle and a call to action.
 * It's actually stackable with more Condensed blocks, one after the other.
 *
 * @interface ICondensedMetricsBodyProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const CondensedMetricsBody: FC<ICondensedMetricsBodyProps> = memo(
  ({ text, icon, cta }) => {
    return (
      <section
        id="metricstext-container"
        className="w-full flex justify-between items-start"
      >
        <div id="metricstext-count" className="w-full flex items-center !gap-4">
          <div id="metricstext-count-icon" className="align-items-center flex">
            <div
              className={`avatar avatar-stats cursor-default p-50 m-0 ${
                icon.color ? `bg-light-${icon.color}` : 'bg-light-primary'
              }`}
            >
              <div className="avatar-content">
                <IconHelper icon={icon.code} size={22} className={icon.color} />
              </div>
            </div>
          </div>
          <div
            id="metricstext-count-block"
            className="flex flex-col justify-center items-start"
          >
            <div
              id="metricstext-count-proper"
              className="font-bold text-[24px] text-[color:--shp-color-text-title]"
            >
              {text.metric ?? 0}
            </div>
            <div
              id="metricstext-count-subtitle"
              className="text-muted text-[color:--shp-color-text-body]"
            >
              {text.subtitle}
            </div>
          </div>
        </div>
        <div
          id="metricstext-cta"
          className="w-full flex justify-end items-center !gap-2 text-[color:--shp-color-link] font-semibold"
        >
          <div
            className="flex justify-start items-center !gap-2 cursor-pointer hover:underline"
            onClick={cta.onClick}
          >
            <span>{cta.text}</span>
            <IconHelper icon="IconCircleArrowRight" />
          </div>
        </div>
      </section>
    );
  },
);
