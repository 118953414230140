export type Documentation = {
  type: string;
  file_url: string;
  date?: number;
};

export type ETDDocument = {
  id: number;
  user_id: number;
  name: string;
  marketplace_id: number;
  marketplace_platform: string;
  transaction_id: string;
  filename: string;
  carrier_ref: string | null;
  type: ETDDocumentType | null;
  used: boolean;
  date: string;
};

export enum ETDDocumentType {
  'Invoice' = '0',
  'AirWaybill' = '1',
  'Proforma' = '2',
  'CertificateOfOrigin' = '3',
  'NaftaCertificateOfOrigin' = '4',
  'CommercialInvoice' = '5',
  'CustomsDeclaration' = '6',
}

export enum PaperlessType {
  Uploaded = 'uploaded',
  NotUploaded = 'not_uploaded',
}
