import styled from 'styled-components';
import { Row } from 'reactstrap';

const StyledContainer = styled(Row)<{
  $fullHeight: boolean;
  $unpadCards: boolean;
}>`
  height: ${props => (props.$fullHeight ? `100vh` : ``)};
  width: 100%;

  overflow: hidden;

  padding: 0 !important;
  margin: 0 !important;

  // ---------------------[ TITLE CUSTOMISATION ]
  .card-with-top-tabs-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.5rem;
    color: var(--shp-color-genericui-primary);
  }

  // ---------------------[ NAVS CUSTOMISATION ]
  .card-top-tabs {
    .nav-tabs {
      margin-bottom: 0;

      .nav-item:not(.nav-item-dropdown):not(.nav-item-dropdown-button),
      .btn-group {
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;

        padding: 0.125rem 1.7rem;

        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;

        transition: background-color 0.1s ease-in-out;

        &:hover {
          background-color: rgba(255, 255, 255, 0.7);
        }

        &:has(.active) {
          background-color: var(--shp-color-bg);

          .active-nav-link {
            font-weight: 700 !important;
            padding: 0 !important;
            color: var(--shp-color-genericui-primary);
          }
        }
      }

      .nav-item-dropdown-button {
        .nav-link {
          padding: 0 !important;
          margin-right: 0.75rem !important;
        }
      }

      .nav-link {
        margin-right: 0;
        font-weight: 500 !important;
        color: var(--shp-color-text-tab-title);

        & + .badge {
          margin: 0;

          &.bg-none {
            padding: 0;

            background-color: transparent !important;
            color: var(--shp-color-genericui-primary);

            font-size: 0.893rem;
            letter-spacing: 0.13px;
          }

          &:not(.bg-none) {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding: 0.35rem;
            border-radius: 500px;
          }
        }
        ::after {
          background: none !important;
          transform: none !important;
        }
      }
    }
  }

  .content-card {
    border-radius: 0;
    box-shadow: none;

    &:not(.tab-pane-card) {
      border-top: solid 1px var(--shp-color-genericui-lightgrey);
    }

    &:not(.no-card) {
      margin: 0 !important;
    }

    .tab-pane-card {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .animated-active-marker {
    transition: all 0.1s ease-in-out;

    z-index: 10;

    bottom: 0px;
    position: absolute;
    height: 2px;
    box-shadow: inset 0 -2px 0 0 var(--shp-color-genericui-primary);
  }

  // ---------------------[ CARD CUSTOMISATION ]
  .card-body {
    padding: ${props => (props.$unpadCards ? `0 !important` : ``)};
  }

  // ---------------------[ CONTENT CUSTOMISATION ]
  .select__control--is-disabled,
  .form-control:disabled,
  .input-group--disabled {
    background-color: #efefef !important;
  }
`;

export default StyledContainer;
