import { FC, memo } from 'react';
import classnames from 'classnames';

import SearchBar from '../search-bar';

/**
 * **[COMPONENT]** The top-bar component.
 * The top navigation bar of the site, displaying the Global Search + other bits&bobs
 *
 * @interface ITopBarProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const TopBar: FC = memo(() => {
  return (
    <>
      <div className="!mb-16" />
      <header
        id="top-bar"
        className={classnames(
          'absolute top-0 left-0 right-0 z-[999]',
          '!py-2',
          'bg-white shadow-sm',
          'flex justify-center items-center',
        )}
      >
        <SearchBar />
      </header>
    </>
  );
});

export default TopBar;
