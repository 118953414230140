import { FC } from 'react';
import classnames from 'classnames';
import { Col, Collapse, DropdownItem } from 'reactstrap';
import { IconHelper } from '@ds-web-iconhelper';
import { INestedDropdownProps } from './types';

/*
 This component is meant to be used inside a reactstrap Dropdown component since reactstrap doesn't offer the possibility to nest
 dropdowns, if you need a normal Dropdown please use the default DropDown component offered by the reactstrap library.
 This component is wrapped in a DropDownItem component and shouldn't be nested in a reactstrap DropdownItem since it's inside a DropdownItem itself.
 The Best component to nest inside this dropdown is a NestedDropDownItem the component can be found inside this folder but feel free to use whatever you
 like if a NestedDropDownItem doesn't do the job for you.

 @author [Carlo Briganti] <carlo.briganti@shippypro.com>
 */
export const NestedDropdown: FC<INestedDropdownProps> = ({
  isOpen,
  title,
  mouseLeave,
  mouseEnter,
  click,
  xDirection,
  icon,
  iconSize,
  className,
  children,
}) => {
  return (
    <DropdownItem
      toggle={false}
      onClick={() => click()}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      className={`${className} relative group`}
    >
      <Col className="fonticon-container flex items-center relative">
        {icon && (
          <div className="fonticon-wrap !mr-4">
            <IconHelper icon={icon} size={iconSize || 13} />
          </div>
        )}
        <label className="fonticon-classname capitalize">{title}</label>
        <div className="flex-1 flex justify-end group">
          {isOpen ? (
            <IconHelper
              className="transform transition-all lg:block lg:visible -translate-x-0.5 group-hover:translate-x-0.5"
              icon="IconChevronRight"
              size={iconSize || 13}
            />
          ) : (
            <IconHelper
              className="transform transition-all block -translate-x-0.5 group-hover:translate-x-0.5"
              icon="IconChevronDown"
              size={iconSize || 13}
            />
          )}
        </div>
      </Col>
      <Collapse
        isOpen={isOpen}
        onMouseLeave={mouseLeave}
        className={classnames(
          'lg:absolute mt-2.5 lg:mt-0 z-10 ',
          'group-hover:visible rounded-[0.357rem]',
          'top-0 lg:shadow-sm md:border-0',
          'border-[1px]',
          'bg-white flex-col justify-center',
          { hidden: !isOpen },
          { 'left-full': xDirection === 'right' },
          { 'right-full': xDirection === 'left' },
        )}
      >
        {children}
      </Collapse>
    </DropdownItem>
  );
};
