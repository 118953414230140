import { Dispatch, SetStateAction } from 'react';

import { ScrollToOptions } from '@tanstack/react-virtual';

export type VirtualisationIndexScrollingObject = {
  setVisibleIndex: VirtualisationScrollFunction;
  visibleIndex: number;
};

export type VirtualisationIndexScrollingHandler = Dispatch<
  SetStateAction<VirtualisationIndexScrollingObject | undefined>
>;

export type VirtualisationScrollFunction = (
  index: number,
  opts?: ScrollToOptions,
) => void;
