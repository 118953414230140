import { Address } from '@web/types/common/address';
import { Parcel } from '@web/types/order';
import { OrderCarrierRates, OrderToManage } from './order';
import { OrderCN22, OrderETD } from './documents';
import { OrderDetails } from './details';
import { CheckAddressOrderResult } from '../types';
import { OrderValidation } from './validation';

/**
 * A common type for every section that allows the user to edit a part of the order.
 * It's used more for UI purpouses than anything else.
 */
export type EditableOrderSection = {
  validateOnMount?: boolean;
  showOrderCardWrapper?: boolean;
  instantOrderUpdate?: boolean;
};

/**
 * A generic State Machine, that can be used to create a manageable resource (address, details, documents list, parcels...).
 * It exposes a single common method called "toAPIPayloadChunk" that will be used to generate the API payload blocks to pass to the Backend.
 */
export type StateMachine<TPayloadChunkType> = {
  toAPIPayloadChunk: (order: OrderToManage) => TPayloadChunkType;
};

/**
 * A generic type that can be used to create a manageable resource (address, details, documents list, parcels...)
 * coming from the order. Said resource can be then used to render and edit a particular modal section.
 */
export type ManageableResource<
  TKey extends string = 'data',
  TValue = undefined,
> = {
  orderId: number;
} & {
  [Key in TKey]: TValue;
};

/**
 * An async resource, with an updateable loading state to the resource.
 */
export type AsyncResource<TKey extends string = 'data', TValue = undefined> = {
  isLoading: boolean;
} & {
  [Key in TKey]: TValue;
};

export type OrderWithSuggestions = {
  orderId: number;
  addressSuggestions: {
    data: CheckAddressOrderResult;
    isSelected: boolean;
  };
  isLoading: boolean;
};

export type ValidationsToManage = ManageableResource<
  'validationsData',
  AsyncResource<'validations', OrderValidation>
>;
export type RecipientAddressToManage = ManageableResource<'address', Address>;
export type SenderAddressToManage = ManageableResource<'addressFrom', Address>;
export type DetailsToManage = ManageableResource<'details', OrderDetails>;
export type CN22ToManage = ManageableResource<
  'cn22Data',
  AsyncResource<'cn22', OrderCN22>
>;
export type ETDDocumentsToManage = ManageableResource<
  'etdData',
  AsyncResource<'etdDocs', OrderETD>
>;
export type ParcelsToManage = ManageableResource<
  'parcelData',
  AsyncResource<'parcels', Parcel[]>
>;
export type RatesToManage = ManageableResource<
  'ratesData',
  AsyncResource<'rates', OrderCarrierRates>
>;
