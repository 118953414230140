import { FC, memo } from 'react';

import { Button, ButtonGroup } from '@ds-web/components';
import { IconHelper } from '@ds-web-iconhelper';
import { Color, StyleType } from '@ds-web/tokens/enums';

import { IDoubleButtonToggleProps } from './types';

/**
 * A reusable component that renders a toggle button comprised of two buttons,
 * one on the left and one on the right.
 *
 * @interface IDoubleButtonToggleProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const DoubleButtonToggle: FC<IDoubleButtonToggleProps> = memo(
  ({
    iconLeft,
    iconRight,
    textLeft,
    textRight,
    leftCode,
    rightCode,
    leftOnClick,
    rightOnClick,
    activeBtn = '',
    color = Color.accent,
    btnGroupClassName = '',
  }) => (
    <ButtonGroup className={btnGroupClassName}>
      <Button
        label={textLeft}
        color={color}
        styleType={StyleType.outline}
        className="btn-icon !rounded-r-none !rounded-l-lg"
        icon={iconLeft ? <IconHelper size={14} icon={iconLeft} /> : undefined}
        active={
          typeof activeBtn === 'string'
            ? leftCode === activeBtn
            : activeBtn[0] === leftCode[0] && activeBtn[1] === leftCode[1]
        }
        onClick={() => leftOnClick(leftCode)}
      >
        {textLeft}
      </Button>
      <Button
        label={textRight}
        color={color}
        styleType={StyleType.outline}
        className="btn-icon !rounded-l-none !rounded-r-lg"
        icon={iconRight ? <IconHelper size={14} icon={iconRight} /> : undefined}
        active={
          typeof activeBtn === 'string'
            ? rightCode === activeBtn
            : activeBtn[0] === rightCode[0] && activeBtn[1] === rightCode[1]
        }
        onClick={() => rightOnClick(rightCode)}
      >
        {textRight}
      </Button>
    </ButtonGroup>
  ),
);
