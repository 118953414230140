import { Spinner } from 'reactstrap';

// TODO: move to design system + add documentation
const CardLoading: React.FC = () => {
  return (
    <div className="w-full h-full flex justify-center items-center m-auto">
      <Spinner color="grey" size="xl" />
    </div>
  );
};

export default CardLoading;
