import { FC, useEffect, useRef, useState } from 'react';

import { Popover } from '@ds-web/components';

import { IPopoverWithClickoutsideProps } from './types';

/**
 * Custom Popover that closes when clicking outside of it or when clicking the ESC key.
 *
 * @interface IPopoverWithClickoutsideProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const PopoverWithClickoutside: FC<IPopoverWithClickoutsideProps> = ({
  children,
  target,
  PopoverComponent = Popover,
  closeOnScroll = false,
  forceClosing = false,
  onClickOutside = () => {},
  ...rest
}) => {
  const popoverContentRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (forceClosing) setIsOpen(false);
  }, [forceClosing]);

  // Closes popover on press Esc
  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        setIsOpen(false);
      }
    };

    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        popoverContentRef.current &&
        !popoverContentRef.current.contains(event.target)
      ) {
        setIsOpen(false);
        onClickOutside();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [target, setIsOpen, onClickOutside]);

  useEffect(() => {
    function handleCloseOnScroll() {
      if (closeOnScroll) setIsOpen(false);
    }
    document.addEventListener('wheel', handleCloseOnScroll);
    return () => {
      document.removeEventListener('wheel', handleCloseOnScroll);
    };
  }, [closeOnScroll]);

  return (
    <PopoverComponent
      target={target}
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      trigger="click"
      placement="top"
      {...rest}
    >
      <div ref={popoverContentRef}>{children}</div>
    </PopoverComponent>
  );
};
